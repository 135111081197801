import styled from 'styled-components'
import { useRouter } from 'next/router'
import Link from 'next/link'
import WorkoutIcon from 'images/workout.png'
import AnalyticsIcon from 'images/analytics.png'
import AnnouncementIcon from 'images/announcement-icon.png'
import MealplanIcon from 'images/mealplan.png'
import React, { useMemo } from 'react'
import useUserData from 'hooks/useUserData'
import { useCollection } from '@sb-konzept/firebase-hooks'
import moment from 'moment'
import { Person } from '@material-ui/icons'

const TabsWrapper = styled.div`
  position: fixed;
  z-index: 1001;
  /* bottom: env(safe-area-inset-bottom); */
  bottom: calc(env(safe-area-inset-bottom) + 3.5rem);
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #2c2c2c;
  height: 3.875rem;
  .keyboard & {
    display: none;
  }
`

const TabImage = styled.img`
  width: 2.125rem;
  height: 2rem;
  opacity: 0.4;
  transition: opacity 300ms ease-in-out;
`

const Tab = styled.div`
  ${props => (props.active ? `${TabImage} {opacity: 1}` : '')}
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`

const A = styled.a`
  position: relative;
  ${({ badge }) =>
    badge
      ? `&:after {
    position: absolute;
    top: -8px;
    right: -14px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    content: "!";
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    color: white;
    background-color: black;
  }`
      : ''}
`

const ProfileIcon = React.forwardRef((props, ref) => (
  <Person ref={ref} {...props} fontSize="large" />
))

const routes = [
  { Icon: WorkoutIcon, path: '/' },
  { Icon: MealplanIcon, path: '/mealplan' },
  { Icon: AnalyticsIcon, path: '/statistic' },
  { Icon: AnnouncementIcon, path: '/announcement' },
  { IconComponent: ProfileIcon, path: '/profile' }
]

function TabNavigation() {
  const router = useRouter()
  const date = useMemo(() => moment().format('YYYY-MM-DD'), [])
  const { value } = useCollection('announcements', q =>
    q
      .where('announcementDate', '<', date)
      .orderBy('announcementDate', 'desc')
      .limit(1)
  )
  const { value: user } = useUserData()

  const badge = useMemo(
    () =>
      (user && !user.announcementsVisited) ||
      (user?.announcementsVisited &&
        value &&
        moment(value?.[0]?.announcementDate) >
          moment(user?.announcementsVisited?.toDate())),
    [value, user?.announcementsVisited]
  )
  return (
    <TabsWrapper>
      {routes.map(({ Icon, IconComponent, path }) => (
        <Tab active={router.pathname === path}>
          <Link href={path}>
            <A badge={path === '/announcement' && badge}>
              {Icon && <TabImage src={Icon} />}
              {IconComponent && <TabImage as={IconComponent} />}
            </A>
          </Link>
        </Tab>
      ))}
    </TabsWrapper>
  )
}

export default TabNavigation
